<template>
  <div class="filters">
    <div class="filters__container">
      <UniversalSelect
        v-model="filters.app"
        style="width: 180px;"
        :options="appsOptions"
        placeholder="App"
        title="App"
        :showSearch="true"
        :disabled="loading || disabled"
        @change="changeAppFilter"
      />
      <UniversalSelect
        v-model="filters.language"
        style="width: 150px;"
        :options="languageOptions"
        placeholder="Language"
        title="Language"
        :showSearch="true"
        :disabled="loading || disabled"
        @change="changeLanguageFilter"
      />
      <UniversalSelect
        v-model="filters.segment"
        style="width: 180px;"
        :options="segmentOptions"
        placeholder="Segment"
        title="Segment"
        :showSearch="true"
        :disabled="loading || disabled"
        @change="changeSegmentFilter"
      />
      <div class="d-flex flex-column">
        <span class="font-weight-bold">Start date</span>
        <a-date-picker
          v-model="filters.startDate"
          format="YYYY-MM-DD HH:mm"
          :show-time="{
            defaultValue: $moment('00:00:00', 'HH:mm'),
            format: 'HH:mm'
          }"
          type="date"
          size="large"
          placeholder="Start date"
          :disabled="loading || disabled"
          @change="changeStartDate"
        />
      </div>
      <div class="d-flex flex-column">
        <span class="font-weight-bold">End date</span>
        <a-date-picker
          v-model="filters.endDate"
          format="YYYY-MM-DD HH:mm"
          :show-time="{
            defaultValue: $moment('00:00:00', 'HH:mm'),
            format: 'HH:mm'
          }"
          type="date"
          size="large"
          placeholder="End date"
          :disabled="loading || disabled"
          @change="changeEndDate"
        />
      </div>
      <UniversalSelect
        v-model="filters.status"
        style="width: 130px;"
        :options="statusOptions"
        placeholder="Status"
        title="Status"
        :disabled="loading || disabled"
        @change="changeStatusFilter"
      />
    </div>
  </div>
</template>

<script>
import UniversalSelect from '@/components/custom/filters/universalSelect.vue'

export default {
  props: {
    appsOptions: {
      type: Array,
      default: () => [],
    },
    languageOptions: {
      type: Array,
      default: () => [],
    },
    segmentOptions: {
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    UniversalSelect,
  },
  data: () => ({
    statusOptions: [
      { name: 'Active', value: 'active' },
      { name: 'Inactive', value: 'inactive' },
    ],
    filters: {
      app: undefined,
      language: undefined,
      segment: undefined,
      startDate: undefined,
      endDate: undefined,
      status: undefined,
    },
  }),
  methods: {
    changeAppFilter(app) {
      this.sendFiltersEvent()
    },
    changeLanguageFilter() {
      this.sendFiltersEvent()
    },
    changeSegmentFilter() {
      this.sendFiltersEvent()
    },
    changeStartDate() {
      this.sendFiltersEvent()
    },
    changeEndDate() {
      this.sendFiltersEvent()
    },
    changeStatusFilter() {
      this.sendFiltersEvent()
    },
    getFilters() {
      return {
        applications: this.filters.app,
        languages: this.filters.language,
        segment: this.filters.segment,
        starts_at: this.filters.startDate ? this.filters.startDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
        ends_at: this.filters.endDate ? this.filters.endDate.format('YYYY-MM-DD HH:mm:ss') : undefined,
        status: this.filters.status,
      }
    },
    sendFiltersEvent() {
      const filters = this.getFilters()
      this.$emit('change', filters)
    },
    resetFilters() {
      this.filters = {
        app: undefined,
        language: undefined,
        segment: undefined,
        startDate: undefined,
        endDate: undefined,
        status: undefined,
      }
    },
  },
}
</script>

<style lang="scss">
.filters {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &__container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}
</style>
